<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <h2>Cadastro de nova triagem</h2>
        <hr />
        <validation-observer ref="observer">
          <form @submit.prevent="registrarTriagem">
            <div class="row">
              <div class="col-md-3">
                <validation-provider
                  name="Frequência cardíaca "
                  rules="required"
                >
                  <v-text-field
                    type="text"
                    v-model="triagem.frequencia_cardiaca"
                    label="Frequência cardíaca (Opcional)"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
              <div class="col-md-3">
                <validation-provider name="Temperatura" rules="required">
                  <v-text-field
                    type="text"
                    v-model="triagem.temperatura"
                    label="Temperatura (Opcional)"
                    v-mask="'##,## ºC'"
                    color="red"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
              <div class="col-md-3">
                <validation-provider name="Peso" rules="required">
                  <v-text-field
                    type="text"
                    v-model="triagem.peso"
                    v-mask="'##,## KG'"
                    label="Peso (Opcional)"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
              <div class="col-md-3">
                <validation-provider name="Altura" rules="required">
                  <v-text-field
                    type="text"
                    v-model="triagem.altura"
                    v-mask="'#,## Metros'"
                    label="Altura (Opcional)"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <validation-provider
                  name="Saturação de Oxigênio"
                  rules="required"
                >
                  <v-text-field
                    type="text"
                    v-model="triagem.saturacao_oxigenio"
                    label="Saturação de Oxigênio (Opcional)"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
              <div class="col-md-4">
                <validation-provider
                  name="Frequẽncia respiratória"
                  rules="required"
                >
                  <v-text-field
                    type="text"
                    v-model="triagem.frequencia_respiratoria"
                    label="Frequência respiratória (Opcional)"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>

              <div class="col-md-4">
                <validation-provider name="Pressão arterial" rules="required">
                  <div style="display: flex; flex-direction: row">
                    <v-text-field
                      type="text"
                      v-model="triagem.pressao_arterial"
                      label="Pressão arterial (Opc)"
                      required
                    ></v-text-field>
                    por
                    <v-text-field
                      type="text"
                      v-model="pressaoResto"
                      label="Pressão arterial (Opc)"
                      required
                    ></v-text-field>
                    mmHg
                  </div>
                </validation-provider>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 mt-4">
                <validation-provider name="Observações" rules="required">
                  <b-form-textarea
                    v-model="triagem.observacoes_triagems"
                    placeholder="Digite suas observações (Opcional)"
                    rows="3"
                    required
                    max-rows="6"
                    label="Observações (Opcional)"
                  ></b-form-textarea>
                </validation-provider>
              </div>
            </div>

            <v-btn
              style="float: right"
              @click="registrarTriagem"
              class="ml-4 mr-4 mt-6 textStyle"
              tile
              color="#1dd1a1"
            >
              <span style="color: white">Cadastrar</span>
            </v-btn>

            <router-link to="/FilaDeAtendimento" v-slot="{ href, navigate }">
              <a :href="href" class="menu-link" @click="navigate">
                <v-btn
                  style="float: right"
                  class="textStyle mt-6"
                  tile
                  color="#1dd1a1"
                >
                  <span style="color: white">Voltar</span>
                </v-btn>
              </a>
            </router-link>
          </form>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import * as ambulatorio from "../../../core/services/ambulatorioAPI.service";
import { required } from "vee-validate/dist/rules";

import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "O campo {_field_} precisa ser preenchido",
});

export default {
  directives: { mask },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  name: "cadastrarContaUsuario",

  data() {
    return {
      errors: "",
      pressaoResto: "",
      invalid: false,
      name: "",
      triagem: {
        frequencia_cardiaca: "",
        temperatura: "",
        peso: "",
        altura: "",
        saturacao_oxigenio: "",
        frequencia_respiratoria: "",
        pressao_arterial: "",
        observacoes_triagem: "",
        paciente_id: this.$route.params.id,
      },
      listPacientes: [],
    };
  },

  methods: {
    registrarTriagem: async function () {
      try {
        this.$refs.observer.validate();

        var data = {
          triagem: {
            frequencia_cardiaca: this.triagem.frequencia_cardiaca,
            temperatura: this.triagem.temperatura,
            peso: this.triagem.peso,
            altura: this.triagem.altura,
            saturacao_oxigenio: this.triagem.saturacao_oxigenio,
            frequencia_respiratoria: this.triagem.frequencia_respiratoria,
            pressao_arterial: `${this.triagem.pressao_arterial} por ${this.pressaoResto} mmHg`,
            observacoes_triagem: this.triagem.observacoes_triagem,
            prontuario_id: this.$route.params.id4,
          },
        };

        await ambulatorio.registerTriagem(data);

        Swal.fire({
          title: "",
          text: "Triagem realizada com sucesso",
          icon: "success",
          confirmButtonColor: "green",
          heightAuto: false,
        });

        setTimeout(() => {
          this.$router.push({ name: "filaDeAtendimento" });
        }, 1000);
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "",
          text: "Erro ao realizar triagem, verifique as informações",
          icon: "error",
          confirmButtonColor: "red",
          heightAuto: false,
        });
      }
    },
  },
  computed: {},

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Nova triagem" }]);
  },
};
</script>

<style>
.theme--light.v-messages {
  color: red !important ;
}
</style>
